import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { formatDate } from "common/utils";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

function calculateGrowthLabel(current_year: number, previous_year: number)
{
  if (previous_year === 0) return "";

  var percent = (((current_year - previous_year) / previous_year) * 100).toFixed(2);
  if (percent === "Infinity" || percent === "NaN") percent = "";
  else percent = (current_year - previous_year <= 0 ? "" : "+") + percent + "%";
  return percent;
}

function mapData(data: { month: string, current_year: number, previous_year: number }[]): any[] {
  const emptyData = () => [
    { x: [], y: [], name: 'Previous Year', type: 'bar' },
    { x: [], y: [], name: 'Current Year', type: 'bar' }
  ];

  if (!data || data.length === 0) return emptyData();

  try {
    const tooltips = data.map(({ month, current_year, previous_year }) => {
      return `<b>${formatDate(`${month}-01`, "MMM")} ${new Date(`${month}-01`).getFullYear() - 1} → ${new Date(`${month}-01`).getFullYear()}</b>
<br />
${previous_year} → ${current_year}
<br />
<b>${calculateGrowthLabel(current_year, previous_year)}</b>`;
    });
    return [
      {
        x: data.map(({ month }) => month),
        y: data.map(({ previous_year }) => previous_year),
        name: 'Previous Year',
        type: 'bar',
        hovertemplate: `%{customdata}<extra></extra>`,
        customdata: tooltips
      },
      {
        x: data.map(({ month }) => month),
        y: data.map(({ current_year }) => current_year),
        name: 'Current Year',
        type: 'bar',
        text: data.map(({ current_year, previous_year }) => `<b>${calculateGrowthLabel(current_year, previous_year)}</b>`),
        textposition: 'auto',
        hovertemplate: `%{customdata}<extra></extra>`,
        customdata: tooltips
      }
    ];
  }
  catch (e) {
    console.error(e)
    return emptyData();
  }
}

export default function MonthlyApplicationsProcessedDelta({ filters }: IMetricsCardFilterProps) {
  const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/University/Overview/MonthlyApplicationsProcessedDelta`, filters)
    .then(x => x.data)
    .then(mapData);

  const layout = {
    ...standardLayout,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    colorway: ['#A9A9A9', '#2076b4']
  };

  const helpInfo = <div>
    <h5>Yearly Change in Applications by Month</h5>
    <p>Difference between the current and previous year in applications submitted, by month. This reports up to the most recent 12 months of the selected timeframe.</p>
    <p><b>Current Year</b> is defined as the reported year, and <b>Previous Year</b> is the year preceding the reported year. For example, if the current timeframe is October 2023 thru November 2023, Current Year records will be October and November 2023, while Previous Year records will be October and November 2022.</p>
    <p><b>Note</b> that this calculation stops and ends on the exact dates provided in the timeframe. If the selected end date is July 10, 2023, then July's data will only include July 1 - July 10 for both 2022 and 2023.</p>
    </div>;

  return <ChartBase title='Yearly Change in Applications by Month' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />;
}