import Layout from 'layout/app/layout';
import { Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppSettings from 'startup/app-settings';
import axios from 'axios';
import { faCircleNotch, faCode } from '@fortawesome/free-solid-svg-icons';
import { mockApiCall, mockListData } from 'common/mock';
import { formatDate, randomDate, randomInt } from 'common/utils';
import AuthorizedArea from 'layout/common/authorized-area';
import { GetAuthToken, GetUserClaims } from 'common/authorization';

// BEGIN Mock framework
const mockAppTypes: string[] = Array.from({ length: 6 }, (_, i) => `Type ${i + 1}`);
const mockRegions: string[] = ["US", "United Kingdom", "France", "India", "Japan", "Brazil", "Australia", "Canada", "Germany", "Italy", "China", "South Korea", "Spain", "Netherlands", "Russia", "Mexico", "Indonesia", "Turkey", "Saudi Arabia", "Switzerland", "Sweden", "Poland", "Belgium", "Norway", "Denmark", "Finland", "Portugal", "Austria", "Greece", "Ireland", "Czech Republic", "Romania", "New Zealand", "Hungary", "Ukraine", "Singapore" ];
const mockOfficers: string[] = 
  ["Alfred", "Bobby", "Charlie", "David", "Edward", "Frank", "George", "Harry", "Ivan", "John", "Kevin", "Larry", "Michael", "Nathan", "Oscar", "Peter", "Quincy", "Robert", "Samuel", "Tom", "Ulysses", "Victor", "William", "Xavier", "Yankee", "Zulu"]
  .map(firstName => [ "Smith", "Johnson", "Williams", "Jones", "Brown", "Davis", "Miller", "Wilson", "Moore", "Taylor", "Anderson", "Thomas", "Jackson", "White", "Harris", "Martin", "Thompson", "Garcia", "Martinez", "Robinson", "Clark", "Rodriguez", "Lewis", "Lee", "Walker", "Hall", "Allen", "Young", "Hernandez", "King", "Wright", "Lopez", "Hill", "Scott", "Green", "Adams", "Baker", "Gonzalez", "Nelson", "Carter", "Mitchell", "Perez", "Roberts", "Turner", "Phillips", "Campbell", "Parker", "Evans", "Edwards", "Collins", "Stewart", "Sanchez", "Morris", "Rogers", "Reed", "Cook", "Morgan", "Bell", "Murphy", "Bailey", "Rivera", "Cooper", "Richardson", "Cox", "Howard", "Ward", "Torres", "Peterson", "Gray", "Ramirez", "James", "Watson", "Brooks", "Kelly", "Sanders", "Price", "Bennett", "Wood", "Barnes", "Ross", "Henderson", "Cole", "Jenkins", "Perry", "Powell", "Long", "Patterson", "Hughes", "Flores", "Washington", "Butler", "Simmons", "Foster", "Gonzales", "Bryant", "Alexander", "Russell", "Griffin", "Diaz", "Hayes" ].map(lastName => `${firstName} ${lastName}`))
  .flat();
const mockAgents: string[] = 
  ["Alfred", "Bobby", "Charlie", "David", "Edward", "Frank", "George", "Harry", "Ivan", "John", "Kevin", "Larry", "Michael", "Nathan", "Oscar", "Peter", "Quincy", "Robert", "Samuel", "Tom", "Ulysses", "Victor", "William", "Xavier", "Yankee", "Zulu"]
  .map(firstName => [ "Smith", "Johnson", "Williams", "Jones", "Brown", "Davis", "Miller", "Wilson", "Moore", "Taylor", "Anderson", "Thomas", "Jackson", "White", "Harris", "Martin", "Thompson", "Garcia", "Martinez", "Robinson", "Clark", "Rodriguez", "Lewis", "Lee", "Walker", "Hall", "Allen", "Young", "Hernandez", "King", "Wright", "Lopez", "Hill", "Scott", "Green", "Adams", "Baker", "Gonzalez", "Nelson", "Carter", "Mitchell", "Perez", "Roberts", "Turner", "Phillips", "Campbell", "Parker", "Evans", "Edwards", "Collins", "Stewart", "Sanchez", "Morris", "Rogers", "Reed", "Cook", "Morgan", "Bell", "Murphy", "Bailey", "Rivera", "Cooper", "Richardson", "Cox", "Howard", "Ward", "Torres", "Peterson", "Gray", "Ramirez", "James", "Watson", "Brooks", "Kelly", "Sanders", "Price", "Bennett", "Wood", "Barnes", "Ross", "Henderson", "Cole", "Jenkins", "Perry", "Powell", "Long", "Patterson", "Hughes", "Flores", "Washington", "Butler", "Simmons", "Foster", "Gonzales", "Bryant", "Alexander", "Russell", "Griffin", "Diaz", "Hayes" ].map(lastName => `${firstName} ${lastName}`))
  .flat();
const mockAgencies: { id: string, name: string }[] = [
  {
      "id": "1666022826769x128903604040105980",
      "name": "Cleverapply Inc"
  },
  {
      "id": "1671134861836x116269907269910530",
      "name": "saboori recruiting "
  },
  {
      "id": "1671156924982x901089599705579500",
      "name": "smith recruiting"
  },
  {
      "id": "1672860819860x911443318535880700",
      "name": "bigham consulting"
  },
  {
      "id": "1672865844970x420696399210348540",
      "name": "smnith consulting"
  },
  {
      "id": "1683029165558x783158282938744800",
      "name": "smith recruiting "
  },
  {
      "id": "1683029979622x169164503271342080",
      "name": "brydon recruiting"
  },
  {
      "id": "1700230149617x409234957673168900",
      "name": "joeys applications"
  },
  {
      "id": "1701202331948x221764010047701000",
      "name": "recruiting"
  },
  {
      "id": "1701204400867x338706394948567040",
      "name": "u.s smith recruiting "
  },
  {
      "id": "1706891183232x118119589009162240",
      "name": "smith recruiting "
  },
  {
      "id": "1717595405310x738558227218694100",
      "name": "bahrain bosses"
  },
  {
      "id": "1717599206841x195620516667850750",
      "name": "student services"
  }
];
const mockStatuses: string[] = [
  "Not Started",
  "In Progress",
  "Submitted",
  "In Review",
  "Missing document",
  "Admitted",
  "Enrolled",
  "Rejected",
  "Withdrawn"];
const mockEnrollmentStatuses: string[] = ["Enrolled", "Not Enrolled"];
const mockSchools: { id: string, name: string }[] = [
  {
      "id": "1666573606322x333338626970091500",
      "name": "Alabama A & M University"
  },
  {
      "id": "1666729622920x599180359918157800",
      "name": "Duquesne University"
  },
  {
      "id": "1666730076144x389119409206067200",
      "name": "Florida Institute of Technology"
  },
  {
      "id": "1666730519727x872187373496041500",
      "name": "Case Western Reserve University"
  },
  {
      "id": "1669768397408x970733175290200000",
      "name": "chatham university"
  },
  {
      "id": "1671109873883x481107832665800700",
      "name": "india university of pennsylvania"
  },
  {
      "id": "1671134510262x791450517897478100",
      "name": "kent state univeirty"
  },
  {
      "id": "1683068525973x524489353350348800",
      "name": "purdue university northwest"
  },
  {
      "id": "1683197252851x986213643711938600",
      "name": "university of findlay"
  },
  {
      "id": "1691491032463x793513638745866200",
      "name": "north park university"
  },
  {
      "id": "1692613541455x539431880688664600",
      "name": "university of lethbridge"
  },
  {
      "id": "1693922903124x899884985886965800",
      "name": "southeast missouri state university"
  },
  {
      "id": "1694049571007x285941744520396800",
      "name": "Glenville State University"
  },
  {
      "id": "1700508922069x128672948226359300",
      "name": "ohio state university"
  },
  {
      "id": "1712074093452x625028482790588400",
      "name": "Post University"
  }
];

const mockIntakeData = ({
  count = 50,
  applicationTypes = mockAppTypes,
  regions = mockRegions,
  officers = mockOfficers,
  agents = mockAgents,
  agencies = mockAgencies,
  statuses = mockStatuses,
  enrollmentStatuses = mockEnrollmentStatuses,
  schools = mockSchools
}: any = {}) => mockApiCall<any>({
  minTime: -1, maxTime: -1,
  result: mockListData(count, i => {
    const submissionDate = randomDate(new Date(2020, 1, 1), new Date());
    const reviewStartDate = randomDate(submissionDate, new Date());
    const completionDate = randomDate(reviewStartDate, new Date());
    const enrollmentDate = randomDate(completionDate, new Date());
    const missingDetailsDate = randomDate(reviewStartDate, completionDate);

    const officer = choose(officers);
    const officerId = officers.findIndex((x: string) => x === officer);
    const agent = choose(agents);
    const agentId = agents.findIndex((x: string) => x === agent);
    const agency = choose(agencies);
    const school = choose(schools);

    const responseTime = Math.round((reviewStartDate.getTime() - submissionDate.getTime()) / (1000 * 60 * 60 * 24));
    const processingTime = Math.round((completionDate.getTime() - reviewStartDate.getTime()) / (1000 * 60 * 60 * 24));

    return {
      application_id: randomInt(1, 2147483647).toString().padStart(10, "0"),
      submission_date: formatDate(submissionDate, "YYYY-MM-DD"),
      review_start_date: formatDate(reviewStartDate, "YYYY-MM-DD"),
      completion_date: formatDate(completionDate, "YYYY-MM-DD"),
      application_type: choose(applicationTypes),
      status: choose(statuses),
      officer_id: (officerId + 1).toString(),
      officer_name: officer,
      agent_id: (agentId + 1).toString(),
      agent_name: agent,
      agency_region: choose(regions),
      enrollment_date: formatDate(enrollmentDate, "YYYY-MM-DD"),
      missing_details_date: formatDate(missingDetailsDate, "YYYY-MM-DD"),
      enrollment_status: choose(enrollmentStatuses),
      invoices_count: randomInt(1, 5),
      total_invoice_amount: randomInt(1000, 10000),
      response_time_days: responseTime,
      processing_time_days: processingTime,
      amount_derived: randomInt(500, 5000),

      agency_id: "000000", // agency.id,
      agency_name: agency.name,
      school_id: "000000", // school.id,
      school_name: school.name
    };
  })
});
if (!Object.hasOwn(window, "mockIntakeData"))
  Object.defineProperty(window, "mockIntakeData", { value: mockIntakeData });

const choose = (arr: any[]) => arr[randomInt(0, arr.length - 1)];
// END Mock framework

function useHealthcheck(apiPath: string) {
  const [status, setStatus] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    axios.get(AppSettings.ApiBase + apiPath)
      .then(response => {
        if (response.status === 200 && response.data === "OK") {
          setStatus(true);
        } else {
          setStatus(false);
        }
      })
      .catch(() => {
        setStatus(false);
      });
  }, [apiPath]);

  return status;
}

function PageContent() {
  // This page is for DEV purposes only, and is to be removed prior to production
  const [mockDataCount, setMockDataCount] = useState<number>(50);
  const [mockData, setMockData] = useState<any[]>([]);

  const databaseStatus = useHealthcheck('/api/v1/Healthchecks/Database');
  const bubbleStatus = useHealthcheck('/api/v1/Healthchecks/Bubble');

  useEffect(() => {
    console.log(process.env);
    console.log(GetAuthToken(), GetUserClaims());
  }, []);

  const returnUrl = window.location.href;
  const authUrl = `${AppSettings.AuthConfig.endpoint}?client_id=${AppSettings.AuthConfig.client_id}&redirect_uri=${encodeURIComponent(`${window.location.origin}${AppSettings.AuthConfig.redirect_uri}?returnUrl=${encodeURIComponent(returnUrl)}`)}`;
  const claims = GetUserClaims();

  return <Container>
    <Row className="pb-5 mx-2 g-2">
      <div className="col-6">
        <h2><FontAwesomeIcon icon={faCode} /> CleverApply Test Landing</h2>
      </div>
      <div className="col-6" style={{ textAlign: "right" }}>
        <AuthorizedArea>
            <AuthorizedArea.WhenUnauthorized content={() => <>Not signed in. <a className="btn btn-link" style={{ background: "none" }} href={authUrl}>Sign in now.</a></>} />
            <AuthorizedArea.WhenAuthorized content={() => <>
                Signed in as: {claims?.name}
                <Link to={"/logout"} className="btn btn-link">Sign Out</Link>
            </>} />
        </AuthorizedArea>
      </div>
      <div className="col-12" style={{ gap: "10px", display: "flex", justifyContent: "center" }}>
        <Link to={`${AppSettings.ApiBase}/swagger/index`} target="_blank" className="btn btn-outline-primary">Explore API</Link>
        <Link to="/university/analytics" className="btn btn-primary">Go to University Analytics</Link>
        <Link to="/agency/analytics" className="btn btn-primary">Go to Agency Analytics</Link>
      </div>

      <div className="col-12 text-center">
        <hr />
      </div>

      <div className="col-12 text-center">
        <Row>
          <div className="col-12 mb-4">
            <h2>System Status</h2>
          </div>
          <div className="col-12 col-md-6">
            {databaseStatus === undefined && <div className="alert alert-info"><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Checking database health...</div>}
            {databaseStatus === true && <div className="alert alert-success"><FontAwesomeIcon icon={faCircleCheck} /> Database connection healthy</div>}
            {databaseStatus === false && <div className="alert alert-warning"><FontAwesomeIcon icon={faCircleXmark} /> Database connection unhealthy</div>}
          </div>
          <div className="col-12 col-md-6">
            {bubbleStatus === undefined && <div className="alert alert-info"><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Checking Bubble connection health...</div>}
            {bubbleStatus === true && <div className="alert alert-success"><FontAwesomeIcon icon={faCircleCheck} /> Bubble connection healthy</div>}
            {bubbleStatus === false && <div className="alert alert-warning"><FontAwesomeIcon icon={faCircleXmark} /> Bubble connection unhealthy</div>}
          </div>
        </Row>
      </div>

      <div className="col-12 text-center">
        <hr />
      </div>

      <div className="col-12 text-center">
        <Row>
          <div className="col-12 mb-4">
            <h2>Mock Data</h2>
          </div>
          <div className="col-12">
            <div className="input-group">
              <input defaultValue={mockDataCount} onChange={(e) => setMockDataCount(parseInt(e.target.value))} type="number" className="form-control" />
              <button className="btn btn-outline-primary" onClick={() => mockIntakeData({ count: mockDataCount }).then(setMockData)}>Generate Mock Records</button>
            </div>
          </div>
          <div className="col-12 mt-3">
            <textarea rows={5} readOnly={true} className="form-control" placeholder="Generate record JSON..." value={JSON.stringify({ data: mockData }, null, 2)}></textarea>
            <small style={{ display: "block", width: "100%", textAlign: "right" }}>{mockData.length} records</small>
          </div>
        </Row>
      </div>
    </Row>
  </Container>;
}

function Page() {

  return (
    <Layout>
      <PageContent />
    </Layout>
  );
}

export default Page;
