import { ChartBase } from "../../chart-base";
import { IMetricsCardFilterProps } from "../../metrics-card-base";
import AppSettings from "startup/app-settings";
import axios from "axios";

interface ITotalEnrollmentConversion {
  total_applications: number;
  total_pending_review: number;
  total_in_review: number;
  total_completed: number;
  total_admitted: number;
  total_pending_action: number;
  total_pending_enrollment: number;
  total_rejected: number;
  total_withdrawn: number;
  total_enrolled: number;
}

export default function TotalEnrollmentConversion({ filters }: IMetricsCardFilterProps) {
  const getData = () =>
    axios.post(`${AppSettings.ApiBase}/api/v1/analytics/Agency/Enrollment/Conversion`, filters)
    .then(x => x.data)
    .then((data: ITotalEnrollmentConversion) => {
      return [
        {
          type: "sankey",
          x: { length: data.total_applications },
          node: {
            pad: 50,
            thickness: 20,
            line: {
              color: "black",
              width: 0.5
            },
            align: "center",
            label: [
              "Submitted (100%)",
              `In Review (${(100 * data.total_in_review / data.total_applications).toFixed(0)}%)`,
              `Complete (${(100 * data.total_completed / data.total_applications).toFixed(0)}%)`,
              `Admitted (${(100 * data.total_admitted / data.total_applications).toFixed(0)}%)`,
              `Rejected (${(100 * data.total_rejected / data.total_applications).toFixed(0)}%)`,
              `Pending Review (${(100 * data.total_pending_review / data.total_applications).toFixed(0)}%)`,
              `Pending Action (${(100 * data.total_pending_action / data.total_applications).toFixed(0)}%)`,
              `Pending Enrollment (${(100 * data.total_pending_enrollment / data.total_applications).toFixed(0)}%)`,
              `Withdrawn (${(100 * data.total_withdrawn / data.total_applications).toFixed(0)}%)`,
              `Enrolled (${(100 * data.total_enrolled / data.total_applications).toFixed(0)}%)`,
            ],
            color: [ "#2076B4", "#FF7F0D", "#2CA02D", "#2CA02D", "#de432f", "#de6831", "#ffe959", "#6239db", "#6239db", "#2CA02D" ],
            hovertemplate: `%{label}: <b>%{value}</b><extra></extra>`,
          },
          link: {
            source: [ 0, 0, 1, 1, 2, 2, 2, 3, 3 ],
            target: [ 1, 5, 2, 6, 3, 4, 8, 9, 7 ],
            // value grouped by column, sorted by high value on top
            value: [
              ...[
                data.total_in_review,
                data.total_pending_review,
              ],
              ...[
                data.total_completed,
                data.total_pending_action,
              ],
              ...[
                data.total_admitted,
                data.total_rejected,
                data.total_withdrawn
              ],
              ...[
                data.total_enrolled,
                data.total_pending_enrollment
              ],
            ],
            arrowlen: 15,
            hovertemplate: `%{target.label}: <b>%{value}</b><extra></extra>`,
          }
        }
      ];
    });

  const layout = {
    margin: { t: 40, b: 40, l: 0, r: 0 },
  };

  const helpInfo = <div>
    <h5>Application Conversion Flow</h5>
    <p>Depicts a high-level overview of where applications which were submitted during the given timeframe are in the process.</p>
  </div>;

  return <ChartBase title='Application Conversion Flow' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />;
}