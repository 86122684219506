import React, { Component, ErrorInfo, ReactNode } from 'react';

interface IErrorBoundaryProps {
    children: ReactNode;
    onError: (error: Error, errorInfo: ErrorInfo) => void;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
    state: IErrorBoundaryState = {
        hasError: false,
    };

    static getDerivedStateFromError(_: Error): IErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.onError(error, errorInfo);
    }

    render() {
        // We can optionally show a fallback UI on exception. Omitting for now
        if (this.state.hasError) {
            return <div hidden data-testid="component-error"></div>;
        }

        return this.props.children;
    }
}