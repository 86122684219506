import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

export default function EnrollmentProportionByAgency({ filters }: IMetricsCardFilterProps) {
  const labelThreshold = 10;

  const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/University/Enrollment/ByAgency`, filters)
    .then(x => x.data)
    .then((data: { agency_name: string, total_enrollments: number }[]) => {
      const totalEnrollments = data.reduce((acc, cur) => acc + parseInt(cur.total_enrollments as any), 0);
      return [
        {
          values: data.map(x => x.total_enrollments),
          labels: data.map(x => x.agency_name),
          type: "pie",
          automargin: true,
          texttemplate: data.map(x => x.total_enrollments / totalEnrollments < labelThreshold ? "" : "%{label}: %{percent}"),
          pull: .05
        }
      ]
    });

  const layout = { 
    ...standardLayout, 
    margin: { ...standardLayout.margin, t: 40, b: 40 },
    legend: {
      x: 1.25,
      y: 0,
      orientation: "v",
      yanchor: "center",
    }
  };

  const helpInfo = <div>
    <h5>Enrollment Proportion by Agency</h5>
    <p>Depicts the proportion of agencies by enrollment count for applications submitted during the given timeframe. The top 20% of agencies (minimum of 10) are reported individually, and the rest are batched into the 'All Others' category.</p>
  </div>;

  return <ChartBase title='Enrollment Proportion by Agency' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />
}