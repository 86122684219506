import { IMetricsCardFilterProps, MetricsCardBase } from "../../metrics-card-base";

export default function ApplicationsCompleted({ filters, action }: IMetricsCardFilterProps) {
  const apiPath = "/api/v1/analytics/agency/overview/ApplicationsCompleted";

  const helpInfo = <div>
    <h5>Applications Completed</h5>
    <p>Number of applications completed by your agency which were submitted during the given timeframe.</p>

    <h6>Calculation:</h6>

    <code>COUNT
      <br />&emsp;&emsp;WHERE [Status] IN ('Withdrawn', 'Rejected', 'Admitted', 'Enrolled')
      <br />&emsp;&emsp;FROM [Agency Applications]
    </code>
  </div>;

  return <MetricsCardBase title='Applications Completed' helpInfo={helpInfo} apiPath={apiPath} unit="Applications" filters={filters} action={action} />;
}