import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

export default function ApplicationsProcessedByOfficer({ filters }: IMetricsCardFilterProps) {
    const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/University/Processing/ByOfficer`, filters)
        .then(x => x.data)
        .then((data: { officer_name: string, count: number }[]) =>
            [{
                x: data.map(d => d.count),
                y: data.map(d => d.officer_name),
                type: "bar",
                orientation: "h",
                text: data.map(d => `${d.officer_name}: ${d.count} applications`),
                insidetextanchor: "start",
                hovertemplate: "%{y}<br />%{x} applications<extra></extra>",
            }]);

    const layout = {
        ...standardLayout,
        margin: { ...standardLayout.margin, b: 40 },
        xaxis: { title: "Applications Processed", dtick: 1 },
        yaxis: { showticklabels: false }
    };

    const helpInfo = <div>
        <h5>Top Application Count Processed by Officer</h5>
        <p>Shows <b>up to</b> the top 10 admissions officers by applications processed for applications submitted in the given timeframe.</p>
    </div>;

    return <ChartBase title='Top Application Count Processed by Officer' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />
}