import { isAuthenticated, isAuthorized } from 'common/authorization';
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';

const authEvents = new EventTarget();

interface IAuthorizedAreaProps {
  children?: ReactNode;
  roles?: string[];
}

export function refreshAuthorizedAreas() {
  authEvents.dispatchEvent(new CustomEvent("auth.refresh"));
}

function AuthorizedArea({ children, roles }: IAuthorizedAreaProps) {
  const [authenticated, setAuthenticated] = useState<boolean>(isAuthenticated());
  const [authorized, setAuthorized] = useState<boolean | undefined>(isAuthorized(...(roles || [])));

  const tAuthorized = (t: any) => t === AuthorizedArea.WhenAuthorized || ![tForbidden, tUnauthorized].some(fn => fn(t));
  const tForbidden = (t: any) => t === AuthorizedArea.WhenForbidden;
  const tUnauthorized = (t: any) => t === AuthorizedArea.WhenUnauthorized;

  const filterChildren = (typeFns: ((t: any) => boolean)[]) => {
    typeFns = typeFns.filter(x => typeof x === "function");
    return (x: any) => React.isValidElement(x) && x.type && typeFns.some(t => t(x.type));
  }

  const t = (condition: any, ttrue: any) => condition === true ? ttrue : null;

  useEffect(() => {
    function reset() {
      setAuthenticated(isAuthenticated());
      setAuthorized(isAuthorized(...(roles || [])));
    }

    authEvents.addEventListener("auth.refresh", reset);
    return () => {
      authEvents.removeEventListener("auth.refresh", reset);
    }
  }, [authenticated, authorized, roles]);

  const toRender = React.Children.toArray(children).filter(filterChildren(
    [
      t(authenticated === false, tUnauthorized),
      t(authorized === false && authenticated === true, tForbidden),
      t(authorized === true && authenticated === true, tAuthorized),
    ]
  ));

  return <>
    {toRender}
  </>;
}

// content is used instead of children to avoid unauthorized content from being rendered in the background

/**
 * User is both authorized and authenticated
 */
AuthorizedArea.WhenAuthorized = ({ content }: { content: () => ReactNode }) =>
  <>{content()}</>;

/**
 * User is authenticated, but not authorized
 */
AuthorizedArea.WhenForbidden = ({ content }: { content: () => ReactNode }) =>
  <>{content()}</>;

/**
 * User is neither authenticated nor authorized
 */
AuthorizedArea.WhenUnauthorized = ({ content }: { content: () => ReactNode }) =>
  <>{content()}</>;

export default AuthorizedArea;
