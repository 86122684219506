import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import AppSettings from 'startup/app-settings';
import { GetAuthToken } from 'common/authorization';

import AppLandingPage from 'pages/app/landing';
import UniversityAnalyticsPage from 'pages/app/university-analytics';
import AgencyAnalyticsPage from 'pages/app/agency-analytics';
import BubbleAuth from 'pages/app/bubble-auth';
import LogOut from 'pages/app/logout';

import { GlobalNavigation } from 'layout/common/global-navigation';
import XsrfGuard from 'startup/xsrf-guard';

axios.interceptors.request.use(
  (config) => {
    const token = GetAuthToken();
    if (token && AppSettings.ApiBase && config.url?.startsWith(AppSettings.ApiBase))
      config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

library.add(far, fas, fab);

const RouterContent = () => {
  return <div className="App">
    <Routes>
      <Route path="/landing" element={<AppLandingPage />} />
      <Route path="/university/analytics" element={<UniversityAnalyticsPage />} />
      <Route path="/agency/analytics" element={<AgencyAnalyticsPage />} />
      <Route path="/logout" element={<LogOut />} />
      <Route path="/auth" element={<BubbleAuth />} />
      <Route path="/*" element={<GlobalNavigation to={AppSettings.BubbleRoot!} />} />
      {/* <Route path="/*" element={<Navigate to="/landing" replace={true} />} /> */}
    </Routes>
  </div>
}

class Content extends Component {
  render() { return (<Router><RouterContent /></Router>); }
}

function App() { return <XsrfGuard content={() => <Content />} />; }

export default App;