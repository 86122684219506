export const newGuid = () => {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const randomInt = (min: number, max: number) => {
    const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}
export const randomDate = (min: Date, max: Date) => new Date(randomInt(min.getTime(), max.getTime()));

export const Arrays = {
    DistinctBy<T>(arr: T[], value: (x: T) => any) {
        return arr.filter((x, i, a) => a.findIndex(y => value(y) === value(x)) === i);
    },
    OrderBy<T>(arr: T[], key: (x: T) => any) {
        return arr.sort((a, b) => {
            const va = key(a);
            const vb = key(b);
            if (va === vb) return 0;
            return va < vb ? -1 : 1;
        });
    },
    GroupBy<TKey, TValue>(arr: TValue[], key: (x: TValue) => TKey) {
        const grouped: { key: TKey, value: TValue[] }[] = [];
        arr.forEach(x => {
            const item = grouped.find(y => y.key === key(x));
            if (item) item.value.push(x);
            else grouped.push({ key: key(x), value: [x] });
        });
        return grouped;
    }
}

export const formatDate = (date: string | number | Date, format: string) => {
    if (typeof date === "string" || typeof date === "number") date = new Date(date);
    if (date === undefined || date.toString() === "Invalid Date")
        throw new Error(`'${date}' is an invalid date`);
    if (typeof format !== "string")
        throw new Error("format must be of type 'string'");

    let toProcess = date as Date;
    const getTimezone = (d: Date) => {
        const tzOffset = d.getTimezoneOffset();
        const tzHours = Math.abs(Math.floor(tzOffset / 60));
        const tzMinutes = Math.abs(tzOffset % 60);
        const tzSign = tzOffset <= 0 ? "+" : "-";
        const tz = `${tzSign}${tzHours.toString().padStart(2, "0")}:${tzMinutes.toString().padStart(2, "0")}`;
        return tz;
    }

    const reg = /(YYYY)|(YY)|(DD)|(D)|(hh)|(h)|(HH)|(H)|(mm)|(m)|(ss)|(s)|(K)|(MMMM)|(MMM)|(MM)|(M)|(TT)|(tt)/g;
    return format.replaceAll(reg, (
        match,
        year_4,
        year_2,
        day_long,
        day_short,
        hour_12_long,
        hour_12_short,
        hour_24_long,
        hour_24_short,
        minute_long,
        minute_short,
        seconds_long,
        seconds_short,
        timezone,
        month_name_long,
        month_name_abbr,
        month_long,
        month_short,
        AM_PM,
        am_pm
    ) => {
        return (year_4 && toProcess.getFullYear().toString())
            || (year_2 && toProcess.getFullYear().toString().slice(-2))
            || (day_long && toProcess.getDate().toString().padStart(2, "0"))
            || (day_short && toProcess.getDate().toString())
            || (hour_12_long && (toProcess.getHours() % 12).toString().padStart(2, "0"))
            || (hour_12_short && (toProcess.getHours() % 12).toString())
            || (hour_24_long && toProcess.getHours().toString().padStart(2, "0"))
            || (hour_24_short && toProcess.getHours().toString())
            || (minute_long && toProcess.getMinutes().toString().padStart(2, "0"))
            || (minute_short && toProcess.getMinutes().toString())
            || (seconds_long && toProcess.getSeconds().toString().padStart(2, "0"))
            || (seconds_short && toProcess.getSeconds().toString())
            || (timezone && getTimezone(toProcess))
            || (month_name_long && ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][toProcess.getMonth()])
            || (month_name_abbr && ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][toProcess.getMonth()])
            || (month_long && (toProcess.getMonth() + 1).toString().padStart(2, "0"))
            || (month_short && (toProcess.getMonth() + 1).toString())
            || (AM_PM && (toProcess.getHours() < 12 ? "AM" : "PM"))
            || (am_pm && (toProcess.getHours() < 12 ? "am" : "pm"))
            || match;
    });
}

const debounceTimers: {
    [key: string]: {
        state: string,
        promise?: Promise<any>,
        timeout?: NodeJS.Timeout,
        resolve: (value: any) => void,
        reject: (reason: any) => void
    }
} = {};
export function debounce<T>(fn: () => T | Promise<T>, ms: number, debounceId: string | undefined = undefined) : Promise<T> {
    const id: string = debounceId || fn?.toString() || newGuid();
    if (debounceTimers[id]) {
        clearTimeout(debounceTimers[id].timeout);
        debounceTimers[id].state = "pending";
    }
    else {
        debounceTimers[id] = {
            resolve: () => { },
            reject: () => { },
            state: "pending",
        };
        debounceTimers[id].promise = new Promise((resolve, reject) => {
            debounceTimers[id].resolve = (v: any) => {
                debounceTimers[id].state = "resolved";
                resolve(v);
            };
            debounceTimers[id].reject = (v: any) => {
                debounceTimers[id].state = "rejected";
                reject(v);
            }
        });
    }

    if (typeof ms === "number" && ms >= 0) {
        debounceTimers[id].timeout = setTimeout(execute, ms);
        return debounceTimers[id].promise!;
    }
    else {
        var promise = debounceTimers[id].promise;
        execute();
        return promise!;
    }

    function execute() {
        try {
            var result = fn();
            debounceTimers[id].resolve(result);
        }
        catch (e) {
            debounceTimers[id]?.reject(e);
        }
        finally {
            delete debounceTimers[id];
        }
    }
}