import { IMetricsCardFilterProps, MetricsCardBase } from "../../metrics-card-base";

export default function PendingActionToComplete({ filters, action }: IMetricsCardFilterProps) {
  const apiPath = "/api/v1/analytics/university/overview/PendingActionToComplete";

  const helpInfo = <div>
    <h5>Average Pending Action to Completion</h5>
    <p>Average turnaround in days between an application requiring further action and when the application is marked as completed, for applications assigned to your university which were submitted during the given timeframe.</p>


    <h6>Calculation:</h6>

    <code>AVERAGE DAYS BETWEEN [Completion Date] AND [Missing Details Date]
      <br />&emsp;&emsp;WHERE [Completion Date] HAS VALUE AND [Missing Details Date] HAS VALUE
      <br />&emsp;&emsp;FROM [University Applications]
    </code>
    </div>;

  return <MetricsCardBase title='Avg Pending Action to Completion' apiPath={apiPath} unit="Days" filters={filters} action={action} helpInfo={helpInfo} />;
}