import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

function mapData(data: { country: string, application_count: number }[]): any[] {
  if (!Array.isArray(data)) data = [];
  return [
    {
      type: 'choropleth',
      locationmode: 'country names',
      locations: (data || []).map(({ country }) => country),
      z: (data || []).map(({ application_count }) => application_count),
      colorscale: 'Reds',
      colorbar: {
        title: 'Applications'
      }
    }
  ];
}

export default function ApplicationsByCountry({ filters }: IMetricsCardFilterProps) {
  const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/University/Overview/ApplicationsByCountry`, filters)
    .then(x => x.data)
    .then(mapData);

  const layout = {
    ...standardLayout,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    geo: {
      projection: {
        type: 'equirectangular'
      }
    }
  };

  const helpInfo = <div>
    <h5>Applications by Country</h5>
    <p>Heatmap of applications submitted by region for those submitted during the given timeframe.</p>
  </div>;

  return <ChartBase title='Applications by Country' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />;
}