import { IMetricsCardFilterProps, MetricsCardBase } from "../../metrics-card-base";

export default function ApplicationsPendingAction({ filters, action }: IMetricsCardFilterProps) {
  const apiPath = "/api/v1/analytics/university/overview/ApplicationsPendingAction";

  const helpInfo = <div>
    <h5>Applications Pending Action</h5>
    <p>Number of applications pending action by your university which were submitted during the given timeframe.</p>

    <h6>Calculation:</h6>

    <code>COUNT
      <br />&emsp;&emsp;WHERE [Status] EQUALS 'Missing Document'
      <br />&emsp;&emsp;FROM [University Applications]
    </code>
  </div>;

  return <MetricsCardBase
      title='Applications Pending Action'
      apiPath={apiPath}
      unit='Applications'
      filters={filters}
      action={action}
      helpInfo={helpInfo}
    />;
}