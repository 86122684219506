import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

export default function ApplicationsProcessedProportionByAgents({ filters }: IMetricsCardFilterProps) {
  const labelThreshold = 10;

  const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/Agency/Processing/ProportionByAgent`, filters)
    .then(x => x.data)
    .then((data: { agent_name: string, total_applications: number }[]) => {
      const totalEnrollments = data.reduce((acc, cur) => acc + parseInt(cur.total_applications as any), 0);
      return [
        {
          values: data.map(x => x.total_applications),
          labels: data.map(x => x.agent_name),
          type: "pie",
          automargin: true,
          texttemplate: data.map(x => x.total_applications / totalEnrollments < labelThreshold ? "" : "%{label}: %{percent}"),
          pull: .05
        }
      ]
    });

  const layout = { 
    ...standardLayout, 
    margin: { ...standardLayout.margin, t: 40, b: 40 },
    legend: {
      x: 1.25,
      y: 0,
      orientation: "v",
      yanchor: "center",
    }
  };

  const helpInfo = <div>
    <h5>Enrollment Proportion by Agent</h5>
    <p>Depicts the proportion of applications processed by agent for applications submitted during the given timeframe. The top 20% of agents (minimum of 10) are reported individually, and the rest are batched into the 'All Others' category.</p>
  </div>;

  return <ChartBase title='Processed Proportion by Agent' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />
}