import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { ValueGetterFunc, GridReadyEvent, ColDef, ColGroupDef } from 'ag-grid-community';

type FilterType = "text" | "number" | "date";
type TextFilterType = "contains" | "notContains" | "equals" | "notEqual" | "startsWith" | "endsWith" | "blank" | "notBlank";
type NumberFilterType = "equals" | "notEqual" | "lessThan" | "lessThanOrEqual" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "blank" | "notBlank";
type DateFilterType = "equals" | "notEqual" | "lessThan" | "greaterThan" | "inRange" | "blank" | "notBlank";

interface ITableFilter {
  type: TextFilterType | NumberFilterType | DateFilterType;
  filter: string | ValueGetterFunc;
}

interface ITableFilterRoot extends ITableFilter {
  filterType: FilterType;
}

interface ITableFilterGroup {
  operator: "AND" | "OR";
  conditions: (ITableFilter | ITableFilterGroup)[];
}

interface ITableFilterGroupRoot extends ITableFilterGroup {
  filterType: FilterType;
}

export interface ITableFilters {
  [field: string]: ITableFilterGroupRoot | ITableFilterRoot
}

interface ITableProps<TData> {
    defaultTableFilters?: ITableFilters;
    columnDefs?: (ColDef<TData> | ColGroupDef<TData>)[];
    rowData?: TData[];

    agGridProps?: AgGridReactProps<TData>;
}

const Table = ({ rowData, columnDefs, defaultTableFilters, agGridProps }: ITableProps<any>) => {

    const onGridReady = (evt: GridReadyEvent<any, any>) => {
        if (typeof agGridProps?.onGridReady === "function")
            agGridProps.onGridReady(evt);

        if (defaultTableFilters)
            evt.api.setFilterModel(defaultTableFilters);
    };

    return <div className="ag-theme-quartz">
        <AgGridReact
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 20, 50]}
            rowData={rowData}
            columnDefs={columnDefs || null}
            rowHeight={30}
            domLayout='autoHeight'

            {...agGridProps}

            onGridReady={onGridReady}
        />
    </div>;
}

export default Table;