import { IMetricsCardFilterProps, MetricsCardBase } from "../../metrics-card-base";

export default function TimePendingAction({ filters, action }: IMetricsCardFilterProps) {
  const apiPath = "/api/v1/analytics/agency/overview/TimePendingAction";

  const helpInfo = <div>
    <h5>Average Time Pending Action</h5>
    <p>Average time between an application requiring further action and when the application is marked as completed, for applications assigned to your agency which were submitted during the given timeframe.</p>


    <h6>Calculation:</h6>

    <code>AVERAGE DAYS BETWEEN [Completion Date] AND [Missing Details Date]
      <br />&emsp;&emsp;WHERE [Completion Date] HAS VALUE AND [Missing Details Date] HAS VALUE
      <br />&emsp;&emsp;FROM [Agency Applications]
    </code>
    </div>;

  return <MetricsCardBase title='Avg Time Pending Action' apiPath={apiPath} unit="Days" filters={filters} action={action} helpInfo={helpInfo} />;
}