import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

export default function AverageTimeToBeginProcessing({ filters }: IMetricsCardFilterProps) {
    const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/Agency/Processing/TimeToBeginProcessing`, filters)
        .then(x => x.data)
        .then((data: { officer_name: string, average: number }[]) =>
            [{
                x: data.map(d => d.average),
                y: data.map(d => d.officer_name),
                type: "bar",
                orientation: "h",
                text: data.map(d => `${d.officer_name}: ${d.average} days`),
                textposition: data.map(x => x.average > 0 ? "inside" : "outside"),
                insidetextanchor: "start",
                hovertemplate: "%{y}<br />%{x} days<extra></extra>"
            }]);

    const layout = {
        ...standardLayout,
        margin: { ...standardLayout.margin, b: 40 },
        xaxis: { title: "Days (average)" },
        yaxis: { showticklabels: false }
    };

    const helpInfo = <div>
        <h5>Longest average time to begin app processing by officer</h5>
        <p>Shows <b>up to</b> the 10 slowest average processing times, by officer, for applications submitted during the given timeframe.</p>
    </div>;

    return <ChartBase title='Longest average time to begin app processing by officer' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />
}