import Layout from 'layout/app/layout';
import { Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { LogOut } from 'common/authorization';
import { useEffect } from 'react';

export function PageContent() {
  useEffect(() => {
    LogOut();
    window.location.assign("/");
  }, [])

  return (
    <Container>
      <Row className="pb-5 mx-2 g-2">
        <div className="d-flex flex-column" style={{ alignItems: "center", gap: "10px" }}>
          <FontAwesomeIcon fontSize={"1.5em"} icon={faCircleNotch} spin={true} /><h4>Logging Out</h4>
        </div>
      </Row>
    </Container>
  );
}

const Page = () =>
  <Layout>
    <PageContent />
  </Layout>;

export default Page;
