import AppSettings from "startup/app-settings";
import { ChartBase, standardLayout } from "../../chart-base";
import axios from "axios";
import { IMetricsCardFilterProps } from "../../metrics-card-base";

export default function TotalEnrollmentByAgency({ filters }: IMetricsCardFilterProps) {
  const getData = () => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/University/Enrollment/TopByAgency`, filters)
    .then(x => x.data)
    .then((data: { agency_name: string, total_enrollments: number }[]) => [
      {
        x: data.map(x => x.total_enrollments),
        y: data.map(x => x.agency_name),
        type: "bar",
        orientation: "h",
        text: data.map(d => `${d.agency_name}: ${d.total_enrollments} enrolled`),
        insidetextanchor: "start",
        hovertemplate: "%{y}<br />%{x} enrolled<extra></extra>",
      }
    ]);

  const layout = {
    ...standardLayout,
    margin: { ...standardLayout.margin, b: 40 },
    xaxis: { title: "Enrolled", dtick: 1 },
    yaxis: { showticklabels: false }
  };

  const helpInfo = <div>
    <h5>Top Enrollment by Agency</h5>
    <p>Shows <b>up to</b> the top 10 agencies which have led to successful enrollments for applications submitted during the given timeframe.</p>
  </div>;

  return <ChartBase title='Top Enrollment by Agency' getDataForPlotly={getData} layout={layout} helpInfo={helpInfo} />
}