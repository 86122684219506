import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleNotch, faExternalLink, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { alert } from "common/modals";
import { debounce } from "common/utils";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import AppSettings from "startup/app-settings";

type MetricValue = number | null;

interface IMetricsCardBaseProps extends IMetricsCardFilterProps {
    title: string | React.ReactNode;
    unit: string | React.ReactNode;
    apiPath: string;
}

type MetricsCardActionCallback = () => void;

interface IMetricsCardAction {
    callback: MetricsCardActionCallback;
    title?: string;
    icon?: IconDefinition;
}

export interface IMetricsCardFilterProps {
    filters: any;
    helpInfo?: string | React.ReactNode;
    action?: IMetricsCardAction | MetricsCardActionCallback;
}

export const MetricsCardBase = ({ title, unit, apiPath, filters, action, helpInfo }: IMetricsCardBaseProps) => {
    const [value, setValue] = useState<MetricValue | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getData = () => debounce(() =>
            axios.post(`${AppSettings.ApiBase}${apiPath}`, filters)
                .then(x => x.data)
                .then(({ value }: { value: number }) => value)
            , 50, `metric_${apiPath}`);

        const updateData = async () => {
            setLoading(true);
            try {
                const v = await getData();
                setValue(v);
            }
            catch (e) {
                console.error(e);
                setError(e as Error);
            }
            setLoading(false);
        };
        updateData();
    }, [filters, apiPath]);

    let content: React.ReactNode = null;
    let showAction = false;
    if (value !== null) {
        content = <>
            <h1>{value}</h1>
            <p>{unit}</p>
        </>;
        showAction = !!action;
    }
    else if (error !== null) {
        content = <><div className="alert alert-warning">Failed to load.</div></>;
    }
    else if (loading) {
        content = <>
            <h1><FontAwesomeIcon spin={true} icon={faCircleNotch} /></h1>
            <p></p>
        </>;
    }
    else {
        content = <>
            <h1>---</h1>
            <p></p>
        </>;
    }

    const actionData = typeof action === "function" ? { callback: action } : action;

    return <Card style={{ height: "100%", border: "none" }}>
        <Card.Body style={{ position: "relative", overflow: "", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center" }}>
            <h5 style={{ color: "#888", padding: "0 10px" }}>{title}</h5>
            {content}
            {actionData && showAction && <button style={{ position: "absolute", bottom: "5px", right: "5px" }} className="btn btn-link btn-sm d-inline-block" title={actionData.title} onClick={() => actionData.callback()}><FontAwesomeIcon icon={actionData?.icon || faExternalLink} /></button>}
            {helpInfo && <button style={{ position: "absolute", top: "5px", right: "5px" }} className="btn btn-link btn-sm d-inline-block" title="About this metric" onClick={() => alert(helpInfo, { dismissButton: { label: "Close" } })}><FontAwesomeIcon icon={faQuestionCircle} /></button>}
        </Card.Body>
    </Card>;
}