import { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import DateRangePicker, { RangeType } from 'rsuite/esm/DateRangePicker';
import { subDays, subMonths, subYears } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import Layout from 'layout/app/layout';
import AuthorizedPage from 'layout/common/authorized-page';
import TotalEnrollmentConversion from './analytics/agency/enrollment/conversion';
import AverageTimeToBeginProcessing from './analytics/agency/application-processing/time-to-begin-processing';
import AverageApplicationTurnaround from './analytics/agency/overview/average-application-turnaround';
import ApplicationsPendingAction from './analytics/agency/overview/applications-pending-action';
import ApplicationsCompleted from './analytics/agency/overview/applications-completed';
import AppSettings from 'startup/app-settings';
import axios from 'axios';
import { debounce } from 'common/utils';
// import { Input, InputGroup, Loader, Pagination, Placeholder, Table } from 'rsuite';
import 'rsuite/Table/styles/index.css';
import 'rsuite/Pagination/styles/index.css';
import 'rsuite/Placeholder/styles/index.css';
import 'rsuite/Loader/styles/index.css';
import { faTable } from '@fortawesome/free-solid-svg-icons';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import AverageDecisionTime from './analytics/agency/application-processing/decision-time';
import Table, { ITableFilters } from 'components/table';
import ApplicationsProcessedProportionByAgents from './analytics/agency/application-processing/proportion-processed-by-agent';
import TimePendingAction from './analytics/agency/overview/time-pending-action';

const currentDate = new Date();
const defaultDateFilter: [Date, Date] = [subDays(currentDate, 29), currentDate];
const currentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const currentYear = new Date(currentDate.getFullYear(), 0, 1);

const defaultRanges: RangeType[] = [
  {
    label: 'Last 7 days',
    value: [subDays(currentDate, 6), currentDate]
  },
  {
    label: 'Last 30 days',
    value: [subDays(currentDate, 29), currentDate]
  },
  {
    label: 'Year to Date',
    value: [currentYear, currentDate]
  },
  {
    label: 'Last Month',
    value: [subMonths(currentMonth, 1), subDays(currentMonth, 1)]
  },
  {
    label: 'Last Year',
    value: [subYears(currentYear, 1), subDays(currentYear, 1)]
  }
];

interface IAgencyAnalyticsFilterOptions {
  schools: { name: string, id: string }[];
}

interface IAgencyAnalyticsFilters {
  start_date: Date;
  end_date: Date;
  school_id?: string;
}

const GraphContent = ({ filters, goToTable }: { filters: IAgencyAnalyticsFilters, goToTable: (withFilters: ITableFilters | null) => void }) => {
  return <>
    <div className="col-12"><h5 className="mb-0 mt-3">Overview</h5></div>
    <div className="col-12 col-sm-6 col-lg-3">
      <AverageApplicationTurnaround filters={filters} />
    </div>
    <div className="col-12 col-sm-6 col-lg-3">
      <TimePendingAction filters={filters} />
    </div>
    <div className="col-12 col-sm-6 col-lg-3">
      <ApplicationsPendingAction filters={filters} action={{
        icon: faTable,
        title: "View in table",
        callback: () => goToTable({
          status: {
            filterType: "text",
            type: "equals",
            filter: "Missing document",
          }
        })
      }} />
    </div>
    <div className="col-12 col-sm-6 col-lg-3">
      <ApplicationsCompleted filters={filters} action={{
        icon: faTable,
        title: "View in table",
        callback: () => goToTable({
          status: {
            filterType: "text",
            operator: "OR",
            conditions: [
              "Enrolled",
              "Withdrawn",
              "Rejected",
              "Enrolled",
            ]
            .map(x => ({ filter: x, type: "equals" }))
          }
        })
      }} />
    </div>
    <div className="col-12"><h5 className="mb-0 mt-3">Agency Processing</h5></div>
    <div className="col-12 col-md-6">
      <ApplicationsProcessedProportionByAgents filters={filters} />
    </div>
    <div className="col-12 col-md-6">
      <AverageTimeToBeginProcessing filters={filters} />
    </div>
    <div className="col-12 col-md-6">
      <AverageDecisionTime filters={filters} />
    </div>
    <div className="col-12"><h5 className="mb-0 mt-3">Enrollment</h5></div>
    <div className="col-12">
      <TotalEnrollmentConversion filters={filters} />
    </div>
  </>;
}

const TableContent = ({ filters, defaultTableFilters }: { filters: IAgencyAnalyticsFilters, defaultTableFilters: null | ITableFilters }) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const getData = () => debounce(() => axios.post(`${AppSettings.ApiBase}/api/v1/analytics/agency`, filters), 50, `agency_analytics_filters`)
      .then(x => x.data);

    setData([]);
    getData().then(setData);
  }, [filters]);

  const [columns] = useState<any[]>([
    { field: "school_name", headerName: "School" },
    { field: "agency_region", headerName: "Region" },
    { field: "status", headerName: "Status" },
    { field: "submission_date", headerName: "Submitted", cellDataType: "date", valueGetter: ({ data, colDef }: any) => new Date(data[colDef.field]) },
    { field: "review_start_date", headerName: "Review Started", cellDataType: "date", valueGetter: ({ data, colDef }: any) => new Date(data[colDef.field]) },
    { field: "missing_details_date", headerName: "Missing Details", cellDataType: "date", valueGetter: ({ data, colDef }: any) => new Date(data[colDef.field]) },
    { field: "completion_date", headerName: "Completed", cellDataType: "date", valueGetter: ({ data, colDef }: any) => new Date(data[colDef.field]) },
    { field: "application_type", headerName: "App Type" },
    { field: "agent_name", headerName: "Agent" },
    { field: "enrollment_date", headerName: "Enrollment", cellDataType: "date", valueGetter: ({ data, colDef }: any) => new Date(data[colDef.field]) },
    { field: "enrollment_status", headerName: "Enrollment Status" },
    { field: "invoices_count", headerName: "Invoices", cellDataType: "number", valueGetter: ({ data, colDef }: any) => parseFloat(data[colDef.field]) },
    { field: "total_invoice_amount", headerName: "Total Invoice Amount", cellDataType: "number", cellRenderer: ({ value }: any) => !isNaN(parseInt(value)) ? <>${parseInt(value).toFixed(2)}</> : <></>, valueGetter: ({ data, colDef }: any) => parseInt(data[colDef.field]) },
    { field: "response_time_days", headerName: "Response Time (Days)", cellDataType: "number", valueGetter: ({ data, colDef }: any) => parseInt(data[colDef.field]) },
    { field: "processing_time_days", headerName: "Processing Time (Days)", cellDataType: "number", valueGetter: ({ data, colDef }: any) => parseInt(data[colDef.field]) }
  ]
    .map(x => ({
      filter: true,
      filterParams: {
        maxNumConditions: 5,
      },
      ...x
    })));

  return <div className="col-12">
    <Table columnDefs={columns} rowData={data} defaultTableFilters={defaultTableFilters || undefined} />
  </div>;
}

export function PageContent() {
  const [filters, setFilter] = useState<IAgencyAnalyticsFilters>({
    start_date: defaultDateFilter[0],
    end_date: defaultDateFilter[1]
  });
  const [filterOptions, setFilterOptions] = useState<IAgencyAnalyticsFilterOptions | null>(null);
  const [showAsTable, setShowAsTable] = useState(false);
  const [defaultTableFilters, setDefaultTableFilters] = useState<ITableFilters | null>(null);

  useEffect(() => {
    debounce(() => axios.get(`${AppSettings.ApiBase}/api/v1/analytics/agency/filters`).then(x => x.data), 50, `agency_analytics_filters`)
      .then(setFilterOptions);
  }, []);

  function goToTable(withFilters: ITableFilters | null) {
    setDefaultTableFilters(withFilters);
    setShowAsTable(true);
  }

  return <Container fluid={false}>
    <Row className="pb-5 mx-2 g-3">
      <div className="col-12 d-flex" style={{ alignItems: "center", gap: "20px" }}>
        <h3 className="d-inline" style={{ verticalAlign: "baseline" }}>Agency Analytics</h3>
        <div style={{ flex: 1 }}></div>
        <button className="btn btn-link" onClick={() => {
          setDefaultTableFilters(null);
          setShowAsTable(v => !v);
         }}>View {showAsTable ? "dashboard" : "as table"}</button>
        <Select
          styles={{
            container: (base) => ({ ...base, width: "fit-content" }),
          }}
          isClearable={true}
          className={`advanced-select`}
          placeholder={!filterOptions?.schools ? "Loading filters..." : "Select a school..."}
          onFocus={e => e.currentTarget.closest(".advanced-select")!.classList.add("active")}
          onBlur={e => e.currentTarget.closest(".advanced-select")!.classList.remove("active")}
          onChange={(data) => setFilter(p => ({ ...p, school_id: (data as SingleValue<{ label: string, value: string }>)?.value as string }))}
          options={!filterOptions?.schools ? [] : filterOptions.schools.map(x => ({ label: x.name, value: x.id }))} />
        <DateRangePicker
          style={{ width: "fit-content" }}
          ranges={defaultRanges}
          caretAs={() => <FontAwesomeIcon icon={faCalendar} />}
          className="form-control p-0"
          format="MM/dd/yyyy"
          character=" - "
          placeholder="MM/DD/YYYY - MM/DD/YYYY"
          showOneCalendar
          defaultValue={defaultDateFilter}
          cleanable={false}
          onChange={data => {
            const [startDate, endDate] = [data?.[0], data?.[1]];
            if (!startDate || !endDate) return;
            setFilter((p) => ({ ...p, start_date: startDate, end_date: endDate }));
          }}
        />
      </div>
      {showAsTable ? <TableContent filters={filters} defaultTableFilters={defaultTableFilters} /> : <GraphContent filters={filters} goToTable={goToTable} />}
    </Row>
  </Container>;
}

const Page = () =>
  <Layout>
    <AuthorizedPage roles={["Agent", "Cleverapply Admin"]}
      content={() => <AuthorizedPage roles={["Owner", "Admin", "Standard"]} content={() => <PageContent />} />}
      />
  </Layout>;

export default Page;
