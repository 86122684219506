import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import AppSettings from 'startup/app-settings';
import { debounce } from './utils';

let token: string | null = null;

interface JwtPayload {
    roles?: string[];
    [key: string]: any;
}

export function GetAuthToken() {
    return token;
    // return localStorage.getItem('token');
}

export function SetAuthToken(value: string) {
    // return localStorage.setItem('token', value);
    token = value;
}

export function ClearAuthToken() {
    // return localStorage.removeItem('token');
    token = null;
}

export function GetUserClaims(): JwtPayload | undefined {
    const token = GetAuthToken();

    if (token) {
        try {
            const claims = jwtDecode<JwtPayload>(token);
            if (claims.exp && Date.now() >= claims.exp * 1000) return undefined;
            return claims;
        } catch (error) {
            console.warn('Failed to decode token', error);
        }
    }

    return undefined;
}

/**
 * Exchanges a leg 1 bubble auth code for an authorization token and stores it in local storage
 * @param code leg 1 auth code from bubble
 * @returns 
 */
export async function ExchangeCodeForAuthToken(code: string) {
    const redirectUri = `${window.location.origin}${AppSettings.AuthConfig.redirect_uri}`;
    const result = await debounce(() => axios.post(`${AppSettings.ApiBase}/api/v1/Authorization/Exchange?code=${code}&redirect_uri=${redirectUri}`), 50, `auth_exchange_${code}`);
    const token = result.headers["authorization"]?.split(" ")[1];
    if (!token) {
        throw new Error("Failed to get token");
    }
    SetAuthToken(token);
}

/**
 * Validates that a request is authenticated
 * @returns User authentication status
 */
export function isAuthenticated(): boolean {
    const claims = GetUserClaims();
    return !!claims?.sub;
}

/**
* Validates that a request is authorized for at least one of the provided roles
* @returns User authorization status
*/
export function isAuthorized(...anyOf: string[]): boolean {
    if (!isAuthenticated()) return false;
    const claims = GetUserClaims();
    return anyOf.length === 0 || (claims!.roles !== undefined && anyOf.some(a => claims!.roles?.includes(a)));
}

export function LogOut() {
    ClearAuthToken();
}