import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Arrays, debounce, formatDate } from "common/utils";
import { ReactNode, useEffect, useState } from "react";
import { Alert, Card } from "react-bootstrap";
import Plot from "react-plotly.js";
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import { subDays } from 'date-fns';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import Select, { MultiValue, SingleValue } from 'react-select';
import axios from 'axios';
import { alert } from "common/modals";

export const standardLayout = {
    // margin: { t: 0, b: 20, l: 0, r: 0 },
    margin: { t: 20, b: 20 },
    legend: { orientation: "h" }
};

export type IFilterOptions = { label: string, value: string }[];
export type IFilterCollection = { [k: string]: string[] | string | undefined | null };
export interface IBaseChartProps {
    helpInfo?: string | React.ReactNode;
    title: string;
    getDataForPlotly: (filters: IFilterCollection) => Promise<any>;
    filters?: { [key: string]: IChartFilter };
    layout?: any;
    render?: (chart: ReactNode) => ReactNode;
}
export interface IChartFilter {
    type: string;
    title: string;
    defaultValue?: any;
    options?: ((data: any[]) => IFilterOptions);
    isMulti?: boolean;
    config?: { [key: string]: any };
    importValueMap?: (key: string, defaultValue: any) => string | number | boolean | bigint | null | { [key: string]: any } | undefined;
}

export const useFilters = (url: string) => {
    const [filters, setFilters] = useState<any | undefined>();
    const [endDate] = useState(new Date());
    const [startDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 30);
        return date;
    });

    useEffect(() => {
        const getFilters = () => axios.get(url)
            .then(x => x.data)
            .then(x => {
                const filters: { [key: string]: IChartFilter } = {
                    // start_date: { type: "date", title: "From", defaultValue: formatDate(startDate, "YYYY-MM-DD") },
                    // end_date: { type: "date", title: "To", defaultValue: formatDate(endDate, "YYYY-MM-DD") },
                    date_range: {
                        type: "date-range",
                        title: "Date Range",
                        defaultValue: { startDate, endDate },
                        config: { startDateKey: "start_date", endDateKey: "end_date" },
                        importValueMap: (key, { startDate, endDate }) => ({ start_date: startDate, end_date: endDate })
                    }
                };
                if (x.agencies) filters.agency_id = {
                    type: "select",
                    title: "All Agencies",
                    isMulti: false,
                    options: () => Arrays.OrderBy(
                        x.agencies.map(({ name, id }: any) => ({ label: name, value: id })),
                        (x: any) => x.label
                    )
                };
                if (x.officers) filters.officer_id = {
                    type: "select",
                    title: "All Reviewers",
                    isMulti: false,
                    options: () => Arrays.OrderBy(
                        x.officers.map(({ name, id }: any) => ({ label: name, value: id })),
                        (x: any) => x.label
                    )
                };
                if (x.applicationTypes) filters.application_type = {
                    type: "select",
                    title: "All Application Types",
                    isMulti: false,
                    options: () => Arrays.OrderBy(
                        x.applicationTypes,
                        (x: any) => x.label
                    )
                };
                return filters;
            });

        if (filters === undefined) {
            getFilters().then(setFilters);
        }
    }, [filters, endDate, startDate, url]);
    return filters;
}

export function ChartBase({ title, getDataForPlotly, filters, layout, render, helpInfo }: IBaseChartProps) {
    render ||= ((chart: ReactNode) => chart);
    const chartLayout = {
        autosize: true,
        ...(layout || {})
    };
    const [data, setData] = useState<any | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilterCollection>({});

    useEffect(() => {
        setData(undefined);
        setError(undefined);
    }, [filter, getDataForPlotly]);
    
    useEffect(() => {
        if (typeof getDataForPlotly === "function" && loading === false && data === undefined && error === undefined) {
            setLoading(true);
            debounce(() => getDataForPlotly(filter), 50, `chart_data_${title}`)
                .then(setData)
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [data, error, loading, getDataForPlotly, filter, filters, title]);

    useEffect(() => {
        setFilter(Object.keys(filters || {})
            .filter(k => filters![k].defaultValue)
            .reduce((c, k) => {
                let values: { [key: string]: any } = {};
                if (typeof filters?.[k]?.importValueMap === "function") {
                    const mappedValue = filters![k]!.importValueMap!(k, filters![k].defaultValue);
                    if (typeof mappedValue === "string") values[k] = mappedValue;
                    else if (typeof mappedValue === "object") values = { ...values, ...mappedValue };
                }
                else values[k] = filters![k].defaultValue;
                return ({ ...c, ...values })
            }, {} as IFilterCollection));
    }, [filters]);

    const dataContent = loading === false && data !== undefined;
    const errorContent = !dataContent && error !== undefined;
    const loadingContent = !dataContent && !errorContent && loading === true;
    const canInteract = dataContent;

    const chartFilterRenderings: { [key: string]: (key: string, config: IChartFilter) => JSX.Element } = {
        "select": (key: string, { title, options, isMulti = true }: IChartFilter) => <Select
            className={`advanced-select`}
            onFocus={e => e.currentTarget.closest(".advanced-select")!.classList.add("active")}
            onBlur={e => e.currentTarget.closest(".advanced-select")!.classList.remove("active")}
            placeholder={dataContent ? title : errorContent ? "" : "Loading..."}
            styles={{
                container: (base) => ({ ...base, pointerEvents: !canInteract ? "none" : "all", width: "fit-content" }),
                control: (base) => ({ ...base, backgroundColor: !canInteract ? "#eee" : "" })
            }}
            isMulti={isMulti}
            isClearable={true}
            onChange={(data) => setFilter((p) => ({ ...p, [key]: isMulti ? (data as MultiValue<{ label: string, value: string }>)?.map(({ value }: any) => value as string) : (data as SingleValue<{ label: string, value: string }>)?.value }))}
            options={dataContent && options ? options(data) : []} />,
        "date": (key: string, { title, defaultValue }: IChartFilter) => <input
            style={{ width: "fit-content" }}
            type="date"
            className={`form-control`}
            defaultValue={defaultValue || ""}
            onChange={(e) => setFilter((p) => ({ ...p, [key]: e.target.value }))} />,
        "date-range": (key: string, { title, defaultValue, config }: IChartFilter) => {
            if (!config) throw new Error("Date range filter requires a config object containing startDateKey and endDateKey properties");
            const ranges: RangeType[] = [
                {
                    label: 'Last 7 days',
                    value: [subDays(new Date(), 6), new Date()]
                },
                {
                    label: 'Last 30 days',
                    value: [subDays(new Date(), 29), new Date()]
                }
            ];
            return <DateRangePicker
                style={{ width: "fit-content" }}
                ranges={ranges}
                caretAs={() => <FontAwesomeIcon icon={faCalendar} />}
                className="form-control p-0"
                format="MM/dd/yyyy"
                character=" - "
                placeholder="MM/DD/YYYY - MM/DD/YYYY"
                showOneCalendar
                defaultValue={defaultValue ? [defaultValue.startDate, defaultValue.endDate] : undefined}
                cleanable={false}
                onChange={data => {
                    const [startDate, endDate] = [data?.[0], data?.[1]];
                    if (!startDate || !endDate) return;
                    setFilter((p) => ({ ...p, [config.startDateKey]: formatDate(startDate, "YYYY-MM-DD"), [config.endDateKey]: formatDate(endDate, "YYYY-MM-DD") }));
                }}
            />
        }
    }

    const hasData = data?.length > 0 && data.some((v:any) => v.x?.length > 0 || v.y?.length > 0 || v.z?.length > 0 || v.values?.length > 0);

    return <Card style={{ height: "100%", border: "none" }}>
        <Card.Body style={{ position: "relative", overflow: "", display: "flex", flexDirection: "column" }}>
            <h5 style={{ flex: 0, color: "#888", textAlign: "center", padding: "0 10px" }}>{title}</h5>
            {filters &&
                <div style={{ display: "flex", alignItems: "start", justifyContent: "start", flexDirection: "row", gap: "10px", flexWrap: "wrap", marginBottom: "2px", flex: 0 }}>
                    {Object.keys(filters).map((k, i) => <div key={i} style={{ width: "fit-content" }}>{chartFilterRenderings[filters[k].type](k, filters[k])}</div>)}
                </div>
            }
            <div className={"w-100"} style={{ flex: 1 }}>
                {dataContent && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", flexDirection: "column", height: "100%", minHeight: "300px" }}>
                    {hasData && render(<Plot data={data} layout={chartLayout} useResizeHandler={true} style={{ width: "100%", height: "100%"/*, maxWidth: "600px"*/, maxHeight: "300px" }} />)}
                    {!hasData && <span style={{ color: "gray" }}>No data to display.</span>}
                </div>}
                {errorContent && <Alert className='m-0 mt-2' variant="danger">An error occurred while loading the data.</Alert>}
                {loadingContent && <Alert className='m-0 mt-2' variant=""><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Loading...</Alert>}
            </div>
            {helpInfo && <button style={{ position: "absolute", top: "5px", right: "5px" }} className="btn btn-link btn-sm d-inline-block" title="About this chart" onClick={() => alert(helpInfo, { dismissButton: { label: "Close" } })}><FontAwesomeIcon icon={faQuestionCircle} /></button>}
        </Card.Body>
    </Card>;
}