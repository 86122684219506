import Layout from 'layout/app/layout';
import { Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GlobalNavigation } from 'layout/common/global-navigation';
import { ExchangeCodeForAuthToken } from 'common/authorization';
import DOMPurify from 'dompurify';

export function PageContent() {
  const [query] = useSearchParams();
  const [code] = useState(query.get("code"));
  const [returnUrl] = useState(query.get("returnUrl")?.toLowerCase().startsWith("/auth") === false ? query.get("returnUrl")! : "/");
  const [fetchedAuthToken, setAuthToken] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetchedAuthToken === undefined && !loading) {
      setLoading(true);
      ExchangeCodeForAuthToken(code!)
        .then(() => setAuthToken(true))
        .catch((e) => {
          setAuthToken(false);
          console.error("Error while fetching token", e);
          setError(e);
        })
        .finally(() => setLoading(false));
    }
  }, [fetchedAuthToken, code, loading]);

  const isLocalReturn = returnUrl.startsWith("/") || returnUrl.startsWith(window.location.origin + "/");
  const sanitizedReturnUrl = DOMPurify.sanitize(returnUrl)

  return (
    <Container>
      <Row className="pb-5 mx-2 g-2">
        {(loading || (!error && !fetchedAuthToken)) && <div data-testid="loading-container" className="d-flex flex-column" style={{ alignItems: "center", gap: "10px" }}>
          <FontAwesomeIcon fontSize={"1.5em"} icon={faCircleNotch} spin={true} /><h4>Logging In</h4>
        </div>}
        {error && !fetchedAuthToken && <div className="alert alert-danger"><span>Failed to login.</span> <a href={sanitizedReturnUrl}>Try again.</a></div>}
        {fetchedAuthToken && isLocalReturn && <Navigate to={sanitizedReturnUrl.replace(window.location.origin + "/", "/")} />}
        {fetchedAuthToken && !isLocalReturn && <GlobalNavigation to={sanitizedReturnUrl} />}
      </Row>
    </Container>
  );
}

const Page = () => <Layout><PageContent /></Layout>;

export default Page;
