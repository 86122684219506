import { ReactNode } from "react";
import AuthorizedArea from "./authorized-area";
import AppSettings from "startup/app-settings";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalNavigation } from "./global-navigation";

const AuthorizedPage = ({ content, roles }: { content: () => ReactNode, roles?: string[] }) => {
  const returnUrl = window.location.href;
  const authUrl = `${AppSettings.AuthConfig.endpoint}?client_id=${AppSettings.AuthConfig.client_id}&redirect_uri=${encodeURIComponent(`${window.location.origin}${AppSettings.AuthConfig.redirect_uri}?returnUrl=${encodeURIComponent(returnUrl)}`)}`;

  return <AuthorizedArea roles={roles || []}>
    <AuthorizedArea.WhenAuthorized content={content} />
    <AuthorizedArea.WhenForbidden content={() => <div className="alert alert-warning">403 - Forbidden</div>} />
    <AuthorizedArea.WhenUnauthorized content={() => <div className="d-flex flex-column" style={{ alignItems: "center", gap: "10px" }}>
      <FontAwesomeIcon fontSize={"1.5em"} icon={faCircleNotch} spin={true} /><h4>Logging In</h4>
      <GlobalNavigation to={authUrl} />
    </div>} />
  </AuthorizedArea>
}

export default AuthorizedPage;