import { IMetricsCardFilterProps, MetricsCardBase } from "../../metrics-card-base";

export default function AverageApplicationTurnaround({ filters, action }: IMetricsCardFilterProps) {
  const apiPath = "/api/v1/analytics/agency/overview/ApplicationTurnaroundTime";
  
  const helpInfo = <div>
    <h5>Average Application Turnaround</h5>
    <p>Average turnaround in days between submission and completion for applications assigned to your agency which were submitted during the given timeframe.</p>

    <h6>Calculation:</h6>

    <code>AVERAGE DAYS BETWEEN [Completion Date] AND [Submission Date]
      <br />&emsp;&emsp;WHERE [Completion Date] HAS VALUE AND [Submission Date] HAS VALUE
      <br />&emsp;&emsp;FROM [Agency Applications]
    </code>
  </div>;

  return <MetricsCardBase title='Average Application Turnaround' apiPath={apiPath} unit="Days" filters={filters} action={action} helpInfo={helpInfo} />;
}