import axios from "axios";
import React, { useEffect, useState } from "react";
import AppSettings from "./app-settings";
import { debounce } from "common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface XsrfProtectionProps {
    content: () => React.ReactNode;
}

/**
 * Loads a CSRF token from the server before rendering the content.
 * @param param0
 * @returns 
 */
export default function XsrfGuard({ content } : XsrfProtectionProps) {
    const [fetched, setFetched] = useState<boolean | null>(null);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                await axios.get(AppSettings.ApiBase + '/');
                setFetched(true);
            } catch (error) {
                console.error('Failed to fetch CSRF token:', error);
                setFetched(false);
            }
        };

        debounce(() => fetchCsrfToken(), 50, "xsrf_protection");
    }, []);

    if (fetched === null)
        return <div className="d-flex flex-column" style={{ alignItems: "center", gap: "10px", paddingTop: "2em" }}>
            <FontAwesomeIcon fontSize={"1.5em"} icon={faCircleNotch} spin={true} /><h4>Loading...</h4>
        </div>;
    else return content();
}