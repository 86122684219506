// global interceptor that logs all console.warn, console.error, and global exceptions to the server for debugging purposes
// It is currently using a local timer, which is implemented per-page. This should be either offloaded to a background worker and shared across all open instances, or have an idempotency validation to avoid clashes in localstorage or duplicate ingestions.

import { debounce, formatDate } from "common/utils";
import AppSettings from "./app-settings";
import ErrorBoundary from "layout/common/error-boundary";
import { GetUserClaims } from "common/authorization";
import axios from "axios";

// withLogging is the only method that should be used in production code
// the rest are exported for unit testing purposes

export type CacheDictionary = { [key: string]: ILog[] };
export interface ILog {
    message: any;
    timestamp: Date;
}

const localCache: ILog[] = [];

export const TELEMETRY_CACHE_PREFIX = "--telemetry-";

export function readLocalCache() { return localCache; }

export function commit(trace: ILog) {
    localCache.push(trace);
    debounce(() => {
        writeCache(localCache);
        localCache.length = 0;
    }, 250, "commit-telemetry-logs");
}

export function parseCache() {
    const out: CacheDictionary= {};
    for (const key in localStorage) {
        if (key.startsWith(TELEMETRY_CACHE_PREFIX)) {
            const data = localStorage.getItem(key);
            try {
                out[key] = data ? JSON.parse(data) as ILog[] : [];
            }
            catch {
                out[key] = [];
            }
        }
    }
    return out;
}

export function writeCache(data: ILog[]) {
    const key = `${TELEMETRY_CACHE_PREFIX}${formatDate(new Date(), "YYYY-MM-DD-HH-mm-ss")}`;
    localStorage.setItem(key, JSON.stringify(data));
}

export function clearCache(data: CacheDictionary) {
    for (const key in data) localStorage.removeItem(key);
}

type consoleWarn = {
    (...data: any[]): void;
    (message?: any, ...optionalParams: any[]): void;
};

export async function uploadTelemetry({ warn }: { warn: consoleWarn }) {
    const data = parseCache();
    const keys = Object.keys(data);
    if (keys.length === 0) return;

    try {
        const telemetry = keys.map(x => data[x]).flat();
        const response = await axios.post(`${AppSettings.ApiBase}/api/v1/Logging/Ingest`,
            { data: telemetry },
            { headers: { "Content-Type": "application/json" } }
        );

        if (response.status >= 200 && response.status < 300) clearCache(data);
        // delete cache if failed to send?
        else warn(`Failed to send telemetry data: Response status did not indicate success.`);
    }
    catch (error) {
        warn(`Failed to send telemetry data: ${error}`);
    }
}

function swallowException(fn: Function, _default: any = undefined) { try { return fn(); } catch { return _default; } }

export function getSupplementaryArgs() {
    const user = swallowException(() => GetUserClaims(), { error: "failed to get user info" });
    const page: any = { url: window.location.href };
    return { user, page };
}

export default function withLogging(handlerRoot: JSX.Element) {
    const _warn = console.warn;
    const _error = console.error;

    // This should be offloaded to a background worker. For now, we'll just use a timer.
    setInterval(() => uploadTelemetry({ warn: _warn }), 30_000);

    console.warn = function (...args) {
        commit({ message: { type: "warning", args: { message: args, ...getSupplementaryArgs() } }, timestamp: new Date() });
        _warn.apply(console, args);
    };

    console.error = function (...args) {
        commit({ message: { type: "error", args: { message: args, ...getSupplementaryArgs() } }, timestamp: new Date() });
        _error.apply(console, args);
    };

    window.onerror = function (message, source, line, col, error) {
        commit({ message: { type: "global_exception", args: { message, source, line, col, error, ...getSupplementaryArgs() } }, timestamp: new Date() });
        return false;
    };

    window.onunhandledrejection = function (event) {
        commit({ message: { type: "unhandled_promise_rejection", args: { error: event.reason, ...getSupplementaryArgs() } }, timestamp: new Date() });
    };

    function handleReactError(error: any, errorInfo: any) {
        commit({ message: { type: "react-error", args: { message: error, errorInfo, ...getSupplementaryArgs() } }, timestamp: new Date() });
    }

    return <ErrorBoundary onError={handleReactError}>{handlerRoot}</ErrorBoundary>
}