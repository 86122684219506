import { CSSProperties, ReactNode } from 'react';
import './layout.scss';

interface ILayoutProps {
  children?: ReactNode;
  style?: CSSProperties;
}

function Layout({ children, style }: ILayoutProps) {

  return (
    <div className={`cleverapply-app no-nav`}>
      {/* <Header /> */}
      {/* <SideNav /> */}
      <main style={{ paddingTop: "2em", display: "flex", justifyContent: "center", ...(style || {}) }}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
