import { Modal } from "react-bootstrap";
import { createPortal } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { newGuid } from "./utils";
import ReactDOM from 'react-dom/client';

interface IAlertModalSettings {
    dismissButton?: {
        label?: string;
        className?: string;
        style?: React.CSSProperties;
    };
    callbacks?: {
        onInit?: (args: { id: string, dismiss: () => void }) => void;
    };
}

interface IAlertModalProps {
    message: string | React.ReactNode;
    onConfirm: () => void;
    onDismiss: () => void;
    settings: IAlertModalSettings;
}

const AlertModal = ({ message, onConfirm, onDismiss, settings }: IAlertModalProps) => {
    const [dialogId] = useState("d" + newGuid());
    const { dismissButton = {} } = settings;
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (typeof settings?.callbacks?.onInit === "function")
            settings.callbacks.onInit({ id: dialogId, dismiss: () => setShow(false) });
    }, [dialogId, settings?.callbacks, settings?.callbacks?.onInit]);

    return createPortal(
        <Modal id={dialogId} show={show} onExiting={() => onConfirm()} onExited={() => setTimeout(onDismiss, 1)}>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <button type="button" className={dismissButton.className || "btn btn-primary"} style={{ width: '6em', ...(dismissButton.style || {}) }} onClick={() => { setShow(false); }}>{dismissButton.label || "OK"}</button>
            </Modal.Footer>
        </Modal>,
        document.body
    );
};

export const alert = (message: string | React.ReactNode, settings: IAlertModalSettings = {}) => {
    return new Promise((resolve) => {
        let value: string | undefined | null = undefined;
        const modalId = "m" + newGuid();

        const onConfirm = () => (value === undefined) ? resolve(value = null) : null;

        const cleanup = () => {
            if (value === undefined) resolve(value = null);
            const modalElement = document.getElementById(modalId);
            if (modalElement) {
                root.unmount();
                document.body.removeChild(modalElement);
            }
        };

        const modalElement = document.createElement('div');
        modalElement.id = modalId;
        document.body.appendChild(modalElement);

        const root = ReactDOM.createRoot(modalElement);
        root.render(
            <AlertModal message={message} settings={settings} onDismiss={cleanup} onConfirm={onConfirm} />
        );
    });
};